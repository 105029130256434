$xl-break-point: 1200px;
$lg-break-point: 992px;
$md-break-point: 768px;
$sm-break-point: 576px;

body {
	font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif;
	line-height: 2.2em;
	color: #212f4f;
	font-size: 1.3em;

}

.anton {
	font-family: 'Anton', sans-serif;
}
.cinzel {
	font-family: 'Cinzel', serif;
}

.about {
	padding: 2em 0;
    width: 60%;
}
.become-author {
	width: 60%;
	margin: 2em auto;
}
.headline {
	padding: 3em 0;
	background-color: black;
	color: white;
}







@media screen and (min-width: $sm-break-point) and (max-width: $md-break-point) {

	.become-author {
		width: 90%;
	}
}

@media screen and (min-width: 0) and (max-width: $sm-break-point) {
	body {
		font-size: 1em;
	}
	h1 {
		font-size: 1.5em;
	}
	.about {
		width: 100%;
	}
	.become-author {
		width: 100%;
	}
	.headline {
		font-size: 1em;
	}
}
